const breakpoints = {
  L: '1008px',
  M: '640px'
}

const rtl =
  (left, right) =>
  ({ theme }) =>
    theme.dir == 'rtl' ? right : left
export const left = rtl('left', 'right')
export const right = rtl('right', 'left')

const sizeL = parseInt(breakpoints.L.replace('px', ''))
const sizeM = parseInt(breakpoints.M.replace('px', ''))
const maxL = sizeL - 1
const minL = sizeL - 48
const maxM = sizeL - 48 - 1
const minM = sizeM + 1

export const HPXTheme = {
  breakpoints,
  mediaQueries: {
    XL: `@media only screen and (min-width: ${breakpoints.L})`,
    L: `@media only screen and (max-width: ${maxL}px) and (min-width: ${minL}px)`,
    M: `@media only screen and (max-width: ${maxM}px) and (min-width: ${minM}px)`,
    S: `@media only screen and (max-width: ${breakpoints.M})`
  }
}
