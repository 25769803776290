import React, { useContext, useEffect, useState } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import IconPeople from '@veneer/core/dist/esm/scripts/icons/icon_people'
import IconCloud from '@veneer/core/dist/esm/scripts/icons/icon_cloud'
import IconConnectivity from '@veneer/core/dist/esm/scripts/icons/icon_connectivity'
import {
  Card,
  Box,
  Col,
  Container,
  FlexRow,
  IconContainer,
  ItemContent,
  ItemDescription,
  ItemTitle,
  SubTitle,
  Text,
  Image,
  FlexAlign,
  Row
} from '../Dashboard/styles'
import { VA } from '../../assets/images'
import useModal from '../../hooks/useModal'
import { deviceInfo } from '@jarvis/web-support-commons/dist/deviceInfo'

const GuidedTroubleShooting = ({ device, navigation }) => {
  const { t } = useI18n()
  const { openVAModal, closeModal } = useModal()
  const [isWWAN, setIsWWAN] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { formatAutoId, environment, authProvider, supportRoute } =
    useContext<any>(RootContext)
  const { isSignedIn } = authProvider
  const { isMobile } = environment || {}

  useEffect(() => {
    async function IsWwanMeteredConnection() {
      const isWWAN = await deviceInfo.isWwanMeteredConnection()
      setIsWWAN(isWWAN)
    }
    IsWwanMeteredConnection()
  }, [])

  useEffect(() => {
    return () => {
      closeModal('VA')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * navigate to va route
   * @param type categories
   */
  const navigationForVA = (type) => {
    // navigation.push(`/support/guidedtroubleshooting`, {
    navigation.push(`${supportRoute}/guidedtroubleshooting`, {
      device: device,
      type: type,
      config: {
        [type]: { isOpen: false }
      },
      navBarTitle: 'common.guidedTroubleshooting'
    })
  }
  return (
    <Row disabled={device?.IsSubscriptionDevice && !isSignedIn}>
      <Container className="m-t-40">
        <Image src={VA} width={24} height={24} marginBottom={10} />
        <SubTitle
          id={formatAutoId('title-guided-trbl')}
          className="subtitle-regular"
          tabIndex={-1}
        >
          {t('common.guidedTroubleShooting')}
        </SubTitle>
        <Container>
          <Row>
            <Card
              id={formatAutoId('va-card')}
              customStyle={{
                background: 'transparent',
                border: '0px'
              }}
              content={
                <FlexAlign>
                  <Text
                    className="caption"
                    tabIndex={-1}
                    id={formatAutoId('decs-guided-trbl')}
                  >
                    {t('common.ourVACanHelpYouDiagnoseAndSolveIssues')}
                  </Text>
                </FlexAlign>
              }
            />
          </Row>
        </Container>{' '}
        <Container marginTop={18}>
          <FlexRow>
            <Col className="troubleshooting" size={4}>
              <Box
                role="button"
                tabIndex="0"
                aria-labelledby={formatAutoId('account-content')}
                onClick={() =>
                  isMobile
                    ? navigationForVA('AccountSupport')
                    : openVAModal(
                        'AccountSupport',
                        t('supportHome.accountSupport'),
                        true,
                        device
                      )
                }
                className="minHeight cardStyle"
                id={formatAutoId('account-card')}
                appearance="outlined"
                hoverable={true}
                content={
                  <>
                    <ItemContent id={formatAutoId('account-content')}>
                      <IconContainer>
                        <IconPeople
                          className="icon-color"
                          color="colorGray10"
                          size={32}
                        ></IconPeople>
                      </IconContainer>
                      <ItemTitle
                        className="body"
                        id={formatAutoId('account-title')}
                      >
                        {t('supportHome.accountSupport')}
                      </ItemTitle>
                      <ItemDescription
                        className="caption-small"
                        id={formatAutoId('account-desc')}
                      >
                        {t('supportHome.reviewYourBillingAndMore')}
                      </ItemDescription>
                    </ItemContent>
                  </>
                }
              ></Box>
            </Col>
            <Col className="troubleshooting" size={4}>
              <Box
                role="button"
                tabIndex="0"
                aria-labelledby={formatAutoId('onedrive-content')}
                onClick={() =>
                  isMobile
                    ? navigationForVA('MSOneDrive')
                    : openVAModal(
                        'MSOneDrive',
                        t('common.msCloudStorageSupport'),
                        true,
                        device
                      )
                }
                className="minHeight cardStyle"
                id={formatAutoId('onedrive-card')}
                appearance="outlined"
                hoverable={true}
                content={
                  <>
                    <ItemContent id={formatAutoId('onedrive-content')}>
                      <IconContainer>
                        <IconCloud
                          className="icon-color"
                          size={32}
                          color="colorGray10"
                        />
                      </IconContainer>
                      <ItemTitle
                        className="body"
                        id={formatAutoId('onedrive-title')}
                      >
                        {t('common.msCloudStorage')}
                      </ItemTitle>
                      <ItemDescription
                        className="caption-small"
                        id={formatAutoId('onedrive-desc')}
                      >
                        {t('common.getSupportForMSCloudStorage')}
                      </ItemDescription>
                    </ItemContent>
                  </>
                }
              ></Box>
            </Col>
            {isWWAN && (
              <Col className="troubleshooting" size={4}>
                <Box
                  role="button"
                  aria-labelledby={formatAutoId('fiveg-content')}
                  tabIndex="0"
                  onClick={() =>
                    isMobile
                      ? navigationForVA('5GConnectivity')
                      : openVAModal(
                          '5GConnectivity',
                          t('common.5GconnectivitySupport'),
                          true,
                          device
                        )
                  }
                  className="minHeight cardStyle"
                  id={formatAutoId('fiveg-card')}
                  appearance="outlined"
                  hoverable={true}
                  content={
                    <>
                      <ItemContent id={formatAutoId('fiveg-content')}>
                        <IconContainer>
                          <IconConnectivity
                            className="icon-color"
                            size={32}
                            color="colorGray10"
                          />
                        </IconContainer>
                        <ItemTitle
                          className="body"
                          id={formatAutoId('fiveg-title')}
                        >
                          {t('supportHome.5GConnectivity')}
                        </ItemTitle>
                        <ItemDescription
                          className="caption-small"
                          id={formatAutoId('fiveg-desc')}
                        >
                          {t('common.getSupportFor5G')}
                        </ItemDescription>
                      </ItemContent>
                    </>
                  }
                ></Box>
              </Col>
            )}
          </FlexRow>
        </Container>
      </Container>
    </Row>
  )
}

export default GuidedTroubleShooting
