import { useContext, useEffect, useState } from 'react'
import { getSupportConsent } from '@jarvis/web-support-commons/dist/privacy'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'

const CAREPACK = [3, 4]

const useWarranty = () => {
  const [optin, setOptin] = useState(null)
  const { localization } = useContext<any>(RootContext)
  const { t } = useI18n()

  useEffect(() => {
    ;(async () => {
      const optin = await getSupportConsent()
      setOptin(optin)
    })()
  }, [])

  const getWarrantyStatus = (status, warrantyEndDate) => {
    if (warrantyEndDate) {
      const dateWithoutz = warrantyEndDate?.replace('Z', '')
      const endDate = new Date(dateWithoutz)
      warrantyEndDate = Intl.DateTimeFormat(localization?.locale).format(
        endDate
      )
    }
    switch (status) {
      case 'Active':
      case 'IW':
        return `${t('common.expires')} ${warrantyEndDate}`
      case 'Expired':
      case 'OW':
        return `${t('common.expired')} ${warrantyEndDate}`
      case 'Unknown':
      case 'UN':
        return `${t('common.unknown')}`
      case 'Pending':
        return `${t('common.pending')}`
      case 'OptinRequired':
        return `${t('common.getDetails')}`
      default:
        return `${t('common.unknown')}`
    }
  }

  const getColor = (status) => {
    switch (status) {
      case 'Active':
      case 'IW':
        return 'colorPositiveActive'
      case 'Expired':
      case 'OW':
        return 'colorNegative'
      case 'Unknown':
      case 'UN':
        return `colorForegroundMedium`
      case 'Pending':
        return 'colorForegroundMedium'
      case 'OptinRequired':
        return 'colorForegroundMedium'
      default:
        return 'colorForegroundMedium'
    }
  }

  const getTitle = (device) => {
    if (device?.carepack || CAREPACK.includes(device?.WarrantyType))
      return t('common.carePack')
    else return t('common.warranty')
  }

  return {
    optin,
    setOptin,
    getWarrantyStatus,
    getColor,
    getTitle
  }
}

export default useWarranty
