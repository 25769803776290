/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { Section } from './styles'
import GuidedTroubleShooting from '../GuidedTroubleshooting'
import DeviceSupport from '../DeviceSupport'
import { parseBool } from '../../utils'
import { withAnalytics } from '@veneer/analytics'
import { genTestId } from '../../utils/index'
import { TestIDPrefix, ContainerComponent } from '../../utils/constants'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'

const TrackSection = withAnalytics(Section)

const Dashboard = (props) => {
  // device?.IsSupportForVA means the language and country needs to be supported on local device and remote device.
  const { device, devices, Skelton, environment, onComplete } = props
  const { authProvider } = useContext<any>(RootContext)
  const { isSignedIn } = authProvider
  const hostDevice = devices?.find((device) => device.IsHostPC === true)

  useEffect(() => {
    onComplete?.()
  }, [])

  const isShowVA = () => {
    const isWrongAccount =
      hostDevice?.IsSubscriptionDevice &&
      isSignedIn &&
      (hostDevice?.DeviceId == 0 || hostDevice?.DeviceId == undefined)
    return (
      (parseBool(device?.IsSubscriptionDevice) &&
        device?.IsSupportForVA &&
        !isWrongAccount) ||
      environment?.isMobile
    )
  }

  return (
    <>
      {device ? (
        <>
          <TrackSection trackId={genTestId(TestIDPrefix, ContainerComponent)}>
            <DeviceSupport {...props}></DeviceSupport>
            {isShowVA() && (
              <GuidedTroubleShooting
                {...props}
                hostDevice={hostDevice}
              ></GuidedTroubleShooting>
            )}
          </TrackSection>
        </>
      ) : (
        <Skelton />
      )}
    </>
  )
}

export default Dashboard
