export const Support = 'Support'
export const BaseScreenPath = '/HpxWinClient'
export const DefaultScreenName = 'SupportHome'
export const DeviceScreenName = 'DeviceSupport'

export const TestIDPrefix = 'Supp'
export const ContainerComponent = 'DevicelistContainer'
export const MFEname = 'DeviceList'
export const DeviceComponent = 'Device'
export const DeviceListAcoordionComponent = 'DeviceListAcoordionComponent'
export const ProductListRefreshComponent = 'ProductListRefreshBtnComponent'
