export const TYPE = {
  MOBILE: 'MD' as Size,
  WINDOWS: {
    SINGLECOLUMN: 'SC' as Size,
    BUNDLEDEVICE: 'BD' as Size,
    MULTIPLECOLUMN: 'MC' as Size
  }
}

/** MobileSize
 * @MD - Mobile Device
 *
 * */
type MobileSize = 'MD'
/** WindowSize
 * @SC - Single Column in a Row
 * @BD - Multiple Column in a Row (Bundle Device)
 * @MC - Multiple Column in a Row
 *
 * */
type WindowSize = 'SC' | 'BD' | 'MC'
export type Size = WindowSize | MobileSize
export interface IProps {
  size?: Size
  device: any
  maxWidth?: number
  getDeviceListWarranty?: any
  Skelton?: any
  background?: any
  isShowWarranty?: boolean
}
