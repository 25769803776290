/**
 * Easily handle strings and boolean
 * @param val
 * @returns boolean
 */
export function parseBool(val) {
  return val === true || val === 'true'
}
export function genTestId(
  moduleId: string,
  componentId: string,
  suffix?: string
): string {
  if (moduleId.length > 4) {
    moduleId = moduleId.slice(0, 4)
  }

  if (componentId.length > 28) {
    componentId = componentId.slice(0, 28)
  }
  // JWebTrackId should not be longer than 32 characters because of simpleUi event schema constraints for the 'componentId' field
  const testJWebId = `${moduleId}${componentId}`
  console.log(testJWebId)
  return testJWebId
}

export function getDeviceType(deviceDetails) {
  const productTypeCode = deviceDetails.ProductType
  let ProductType = ''
  if (productTypeCode == 2) {
    // Notebook
    ProductType = 'Notebook'
  } else if (productTypeCode == 5) {
    // Desktop
    ProductType = 'Desktop'
  } else if (productTypeCode == 6) {
    // Monitor
    ProductType = 'Monitor'
  } else if (productTypeCode == 7) {
    // Scanner
    ProductType = 'Scanner'
  } else if (productTypeCode == 3) {
    // Tablet
    ProductType = 'Tablet'
  } else if (productTypeCode == 1) {
    // Printer
    ProductType = 'Printer'
  } else if (productTypeCode == 4) {
    // Calculator
    ProductType = 'Calculator'
  } else if (productTypeCode == 8) {
    // Mobile
    ProductType = 'Mobile'
  } else if (productTypeCode == 9 || productTypeCode == 30) {
    // Accessory
    ProductType = 'Accessory'
  } else if (productTypeCode == 50) {
    // Pen
    ProductType = 'Pen'
  } else if (productTypeCode == 51) {
    // Keyboard
    ProductType = 'Keyboard'
  } else if (productTypeCode == 52) {
    // Mouse
    ProductType = 'Mouse'
  } else if (productTypeCode == 53) {
    // KBM
    ProductType = 'KBM'
  } else if (productTypeCode == 70) {
    // Headset
    ProductType = 'Headset'
  } else if (productTypeCode == 80) {
    // Phone
    ProductType = 'Phone'
  } else if (productTypeCode == 100) {
    // VideoConferencing
    ProductType = 'VideoConferencing'
  }
  return ProductType
}
