import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import DeviceView from '../DeviceView'
import { Box, Col, FlexRow } from '../../Dashboard/styles'
import { TYPE } from '../DeviceView/types'

const DeviceAccordion = ({ device }) => {
  const { formatAutoId, featureFlag, environment } =
    useContext<any>(RootContext)
  const accessories =
    device?.Subscriptions?.[0]?.Entities?.[0]?.LinkedEntities?.filter(
      (item) => item.Type === 'accessories'
    )

  const { isMobile } = environment || {}

  const Content = (device) => {
    return (
      <>
        <FlexRow minWidth={256}>
          <Col gridColumn="1/-1">
            <DeviceView
              background="colorBackground"
              device={device}
              size={isMobile ? TYPE.MOBILE : TYPE.WINDOWS.SINGLECOLUMN}
            ></DeviceView>
          </Col>
        </FlexRow>
        {featureFlag?.deviceListEnabled && accessories?.length > 0 && (
          <FlexRow
            minWidth={isMobile == false && 186}
            maxWidth={isMobile == false && 328}
            className="m-t-16"
            L={2}
            M={2}
            S={2}
          >
            {accessories?.map((item, index) => {
              return (
                item?.Type === 'accessories' && (
                  <Col
                    flex={isMobile === false && 1}
                    key={index}
                    gridColumn={isMobile ? '1/-1' : ''}
                  >
                    <DeviceView
                      background="colorBackground"
                      size={isMobile ? TYPE.MOBILE : TYPE.WINDOWS.BUNDLEDEVICE}
                      device={{ ...item, ProductName: item?.Name }}
                    ></DeviceView>
                  </Col>
                )
              )
            })}
          </FlexRow>
        )}
      </>
    )
  }

  return (
    <>
      <Box
        id={formatAutoId(`device_item_${device.SerialNumber}`)}
        className="cardStyle hp-one-view"
        hoverable={false}
        tabIndex={-1}
        content={Content(device)}
      ></Box>
    </>
  )
}

export default DeviceAccordion
