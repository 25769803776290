import { useContext } from 'react'
import { LocalizationType } from '../types/localization'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'

interface IModal {
  modals: any
  openModal: (modal) => void
  setResize: (resize: boolean) => void
  setOpen: (isOpen: boolean) => void
  closeModal: (modal) => void
  setHide: (name, hide) => void
  updateModal: (name, param) => void
}

export interface IContext {
  localization?: LocalizationType
  useModal?: () => IModal
}

const useModal = () => {
  const { useModal } = useContext<IContext>(RootContext)
  const { openModal, modals, closeModal, setHide, updateModal } = useModal()
  const openVAModal = (
    type: string,
    contextTitle: string,
    isOpen: boolean,
    device: any
  ) => {
    let hide = false
    switch (type) {
      case 'AccountSupport':
        hide =
          device?.vaIntentsForHPOneList['AccountSupport'] === 1 &&
          !modals?.VA?.config?.['AccountSupport']?.isOpen
            ? true
            : false
        break
      case 'MSOneDrive':
        hide =
          device?.vaIntentsForHPOneList['MSOneDrive'] === 1 &&
          !modals?.VA?.config?.['MSOneDrive']?.isOpen
            ? true
            : false
        break
      case '5GConnectivity':
        hide =
          device?.vaIntentsForHPOneList['5GConnectivity'] === 1 &&
          !modals?.VA?.config?.['5GConnectivity']?.isOpen
            ? true
            : false
        break
      default:
        break
    }
    const VA = {
      isOpen: isOpen,
      category: {
        type: type,
        contextTitle: contextTitle
      },
      config: {
        [type]: { isOpen: false },
        ...modals?.VA?.config
      },
      resize: false,
      width: '75%',
      hide: hide,
      requireAuthLogin: device?.IsSubscriptionDevice ? getUniqueValue() : false,
      props: { device }
    }
    console.log(modals)
    if (modals?.VA) {
      updateModal('VA', VA)
    } else {
      openModal({ VA })
    }
  }

  const getUniqueValue = () => Date.now() + Math.random()

  return {
    openModal,
    openVAModal,
    closeModal,
    setHide,
    modals
  } as any
}

export default useModal
