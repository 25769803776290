import styled, { css } from 'styled-components'
import Tokens from '@veneer/tokens'
import card from '@veneer/core/dist/esm/scripts/card'
import { left, right } from '../../../styles/global'
import customimage from '@veneer/core/dist/esm/scripts/custom_image'
import { TYPE } from './types'
import { withAnalytics } from '@veneer/analytics'
const TrackCard = withAnalytics(card)

const flexRow = css`
  display: flex;
  flex-direction: row;
`
const flexColumn = css`
  display: flex;
  flex-direction: column;
`
export const Card = styled(TrackCard)`
  padding: ${({ theme }) => (theme?.isMobile ? Tokens.space4 : Tokens.space6)};
  height: 100%;
  width: 100%;
  border-color: ${({ theme }) => theme.colorBorder} !important;
  border-radius: ${Tokens.borderRadius3} !important;
  &.pointer {
    cursor: pointer;
  }
  ${(props) =>
    props.background &&
    css`
      background-color: ${({ theme }) => theme[props.background]} !important;
    `}
  ${(props) =>
    props.size === TYPE.WINDOWS.MULTIPLECOLUMN &&
    css`
      padding: ${Tokens.space4};
    `}
    ${(props) =>
      props.showShadow === true &&
      css`
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      `}
  .m-t-16 {
    margin-top: ${Tokens.space4};
  }
  .m-t-8 {
    margin-top: ${Tokens.space2};
  }
  .m-t-4 {
    margin-top: ${Tokens.space1};
  }
  .m-l-4 {
    margin-${left}: ${Tokens.space1};
  }
`
export const FlexBox = styled.div`
  ${flexRow};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: row-reverse !important;
    `}
  .align {
    align-items: center;
    justify-content: center;
  }
  ${(props) =>
    props.size === TYPE.WINDOWS.BUNDLEDEVICE &&
    css`
      flex-direction: column-reverse;
      .align {
        align-items: center;
      }
    `}
  ${(props) =>
    props.size === TYPE.WINDOWS.MULTIPLECOLUMN &&
    css`
      flex-direction: column-reverse;
      height: 100%;
      .align {
        align-items: center;
      }
    `}
  .m-t-12 {
    margin-top: ${Tokens.space3};
  }
`
export const Col = styled.div`
  ${flexColumn};
  &.minWidth0 {
    min-width: 0;
  }
  ${(props) =>
    props.flex !== undefined &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
`

export const Label = styled.span`
  color: ${({ theme }) => theme.colorForegroundLight};
  ${({ theme }) =>
    theme?.isMobile &&
    css`
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
  &.foreground {
    color: ${({ theme }) => theme.colorForeground};
  }
  &.flex-1 {
    flex: 1;
  }
`
export const Value = styled.span`
  color: ${({ theme, color }) =>
    color ? theme[color] : theme.colorForegroundMedium};
  display: flex;
  ${(props) =>
    props.copy &&
    css`
      -webkit-user-select: text !important;
      -ms-user-select: text !important;
      user-select: text !important;
    `}
`
export const Title = styled.h6`
  color: ${({ theme }) => theme.colorForegroundMedium};
  word-break: break-word;
`

export const Image = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`

export const ImageContainer = styled.div`
  ${(props) =>
    props.size === TYPE.WINDOWS.MULTIPLECOLUMN &&
    css`
      height: 62px;
      margin-bottom: ${Tokens.space6};
      display: flex;
      align-items: center;
    `}
  ${(props) =>
    props.size === TYPE.WINDOWS.SINGLECOLUMN &&
    css`
      height: 167px;
    `}
${(props) =>
    props.size === 'BD' &&
    css`
      height: 60px;
    `}
${({ theme }) =>
    theme.isMobile &&
    css`
      display: flex;
      width: 56px;
      height: auto;
      margin-${right}: ${Tokens.space4}
    `}
`
export const CustomImage = styled(customimage)`
  color: ${(props) =>
    props?.color
      ? props.theme?.[props?.color]
      : props.theme?.colorForeground} !important;
  width: ${(props) => props.size}px !important;
`
