import React, { Suspense, useContext, useEffect } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import {
  Card,
  Col,
  FlexBox,
  Label,
  Title,
  Value,
  Image,
  ImageContainer
} from './style'
import useImage from '../../../hooks/useImage'
import useWarranty from '../../../hooks/useWarranty'
import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import { ProductIconPlaceholder } from '@jarvis/react-hpx-support-shared/dist/components/ProductIcon/style'
import { IProps, TYPE } from './types'
import { genTestId, getDeviceType } from '../../../utils'
import { TestIDPrefix, DeviceComponent } from '../../../utils/constants'
import useOutsideClick from '@jarvis/react-hpx-support-shared/dist/hooks/useOutsideClick'

const ProductIconLazy = React.lazy(
  () => import('@jarvis/react-hpx-support-shared/dist/components/ProductIcon')
)

const ProductIcon = ({ size, color, type }) => {
  return (
    <Suspense
      fallback={
        <ProductIconPlaceholder single={true} height={size} width={size} />
      }
    >
      <ProductIconLazy size={size} color={color} type={type} />
    </Suspense>
  )
}

const DeviceView = ({
  size = TYPE.WINDOWS.SINGLECOLUMN,
  device,
  maxWidth = null,
  getDeviceListWarranty,
  Skelton,
  background = null,
  isShowWarranty = false
}: IProps) => {
  const { formatAutoId, optinProvider, navigation, navigatePath } =
    useContext<any>(RootContext)
  const { t } = useI18n()
  const { loading, onLoad, onError } = useImage()
  const { getWarrantyStatus, getColor, getTitle } = useWarranty()
  const { optinConfirm, optin } = optinProvider || {}

  const handler = (event) => {
    if (event?.which !== 3 && !event.target.getAttribute('data-copy'))
      window?.getSelection()?.removeAllRanges()
  }

  useEffect(() => {
    try {
      const elem = document.querySelector(
        `#${formatAutoId(`device_card_${device.SerialNumber}`)}`
      )
      elem?.setAttribute('role', 'link')
      // eslint-disable-next-line no-empty
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useOutsideClick(handler)

  const redirect = () => {
    console.log('******* Device Click ********')
    console.log(navigatePath)

    navigation.push(
      // `support/device/${device?.DeviceId || device?.SerialNumber}`,
      `/${navigatePath}/${device?.DeviceId || device?.SerialNumber}`,
      {
        device,
        navBarTitle: 'common.deviceSupport'
      }
    )
  }

  const ProductDetails = () => {
    return (
      <>
        {[TYPE.WINDOWS.SINGLECOLUMN, TYPE.WINDOWS.MULTIPLECOLUMN].includes(
          size
        ) === true && (
          <>
            <Label
              role="text"
              tabIndex={-1}
              className="label-small m-t-16"
              id={formatAutoId(`pn_label_${device.SerialNumber}`)}
            >
              {t('common.productNumber', 'Product number')}
            </Label>
            <Value
              onClick={(e) => {
                if (window.getSelection().toString()) {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              copy
              role="text"
              tabIndex={-1}
              className="caption-small m-t-4"
              id={formatAutoId(`pn_value_${device.SerialNumber}`)}
              data-copy="true"
            >
              {device.ProductNumber}
            </Value>
            <Label
              role="text"
              tabIndex={-1}
              className="label-small m-t-8"
              id={formatAutoId(`sn_label_${device.SerialNumber}`)}
            >
              {t('deviceSupport.serialNumber', 'Serial number')
                .replace(':', '')
                .replace('：', '')}
            </Label>
            <Value
              onClick={(e) => {
                if (window?.getSelection()?.toString()) {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              copy
              role="text"
              tabIndex={-1}
              className="caption-small m-t-4"
              id={formatAutoId(`sn_value_${device.SerialNumber}`)}
              data-copy="true"
            >
              {device.SerialNumber}
            </Value>
            {isShowWarranty && (
              <>
                <Label
                  role="text"
                  tabIndex={-1}
                  className="caption-small m-t-8"
                  id={formatAutoId(`warranty_status_${device.SerialNumber}`)}
                >
                  {getTitle(device)}
                </Label>
                <Value
                  role="text"
                  color={getColor(device?.WarrantyStatus)}
                  tabIndex={-1}
                  className="caption-small m-t-4"
                  id={formatAutoId(`warranty_date_${device.SerialNumber}`)}
                >
                  {!optin ? (
                    <>
                      <a
                        id={formatAutoId(
                          `warranty_getdetails_${device.SerialNumber}`
                        )}
                        tabIndex={0}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          optinConfirm?.({
                            okCallback: () => {
                              getDeviceListWarranty()
                            },
                            device
                          })
                        }}
                      >
                        {t('common.getDetails')}
                      </a>
                    </>
                  ) : device?.WarrantyLoading == true ? (
                    Skelton
                  ) : (
                    <>
                      <IconCalendarDay
                        size={14}
                        color={getColor(device?.WarrantyStatus)}
                      ></IconCalendarDay>
                      <span className="m-l-4">
                        {getWarrantyStatus(
                          device?.WarrantyStatus,
                          device?.WarrantyEndDate
                        )}
                      </span>
                    </>
                  )}
                </Value>
              </>
            )}
          </>
        )}
      </>
    )
  }

  const ProductImage = () => {
    return (
      <>
        {loading && (
          <ImageContainer size={size}>
            <ProductIcon
              size={
                [TYPE.WINDOWS.SINGLECOLUMN].includes(size)
                  ? 144
                  : size === TYPE.MOBILE
                  ? 52
                  : 62
              }
              type={device.ProductType || 2}
              color="colorForegroundLight"
            ></ProductIcon>
          </ImageContainer>
        )}
        {
          <ImageContainer
            size={size}
            style={{ display: loading ? 'none' : '' }}
          >
            <Image
              src={device?.ImageLink}
              onLoad={onLoad}
              onError={onError}
              alt=""
            />
          </ImageContainer>
        }
      </>
    )
  }

  const ProductName = () => {
    return (
      <Label
        className={
          size === TYPE.WINDOWS.MULTIPLECOLUMN
            ? 'label foreground flex-1'
            : 'label-small'
        }
        role="text"
        tabIndex={-1}
        id={formatAutoId(`productname_${device.SerialNumber}`)}
      >
        {device.ProductName}
      </Label>
    )
  }

  const NickName = () => {
    return (
      <>
        {size !== TYPE.WINDOWS.MULTIPLECOLUMN && (
          <>
            <Title
              className={
                size === TYPE.WINDOWS.BUNDLEDEVICE
                  ? 'label m-t-12'
                  : 'subtitle-regular'
              }
              role="text"
              tabIndex={-1}
              id={formatAutoId(`nickname_${device.SerialNumber}`)}
            >
              {device.NickName}
            </Title>
          </>
        )}
      </>
    )
  }

  const Content = () => {
    return (
      <>
        <FlexBox size={size} maxWidth={maxWidth} key={device.SerialNumber}>
          <Col
            flex={1}
            justifyContent={size == TYPE.MOBILE && 'center'}
            className={size == TYPE.MOBILE && 'minWidth0'}
            id={formatAutoId(`device-data-${device.SerialNumber}`)}
          >
            {[TYPE.WINDOWS.SINGLECOLUMN].includes(size) && (
              <ProductIcon
                size={24}
                type={device.ProductType || 2}
                color="colorForegroundLight"
              ></ProductIcon>
            )}
            <NickName />
            <ProductName />
            <ProductDetails />
          </Col>
          <Col
            className="align"
            flex={
              [TYPE.MOBILE, TYPE.WINDOWS.MULTIPLECOLUMN].includes(size) ===
              false
                ? 1
                : 0
            }
          >
            <ProductImage />
          </Col>
        </FlexBox>
      </>
    )
  }

  if (!device) return null

  return (
    <>
      <Card
        id={formatAutoId(`device_card_${device.SerialNumber}`)}
        trackId={genTestId(TestIDPrefix, `${getDeviceType(device)}`)}
        trackCategory={`${DeviceComponent}-${device?.SerialNumber}`}
        onClick={redirect}
        aria-labelledby={formatAutoId(`device-data-${device.SerialNumber}`)}
        size={size}
        className="cardStyle pointer"
        hoverable={true}
        tabIndex={0}
        content={Content()}
        background={background}
        showShadow={isShowWarranty}
      ></Card>
    </>
  )
}

export default DeviceView
