import React, { useContext, useEffect, useMemo, useState } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { launchUrlFromCommand } from '@jarvis/web-support-commons/dist/launcher/launcher_win'
import { IDevice } from '@jarvis/web-support-commons/dist/methone/devices/types/device'
import {
  Col,
  Container,
  FlexRow,
  Row,
  SubTitle,
  Text,
  Button
} from '../Dashboard/styles'
import DeviceAccordion from './DeviceAccordion'
import createDOMPurify from 'dompurify'
import IconReload from '@veneer/core/dist/esm/scripts/icons/icon_reload'
import DeviceView from './DeviceView'
import { TYPE } from './DeviceView/types'
import { genTestId } from '../../utils/index'
import {
  TestIDPrefix,
  ProductListRefreshComponent
} from '../../utils/constants'
const DOMPurify = createDOMPurify(window)

const DeviceSupport = ({
  devices,
  device,
  updateScreen,
  loading,
  Skelton,
  defaultScreenName,
  refreshDeviceList,
  getDeviceListWarranty
}) => {
  const { t } = useI18n()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { formatAutoId, authProvider, environment } =
    useContext<any>(RootContext)
  const [otherDevices, setOtherDevices] = useState<IDevice[]>([])
  const [subscriptionDevice, setSubscriptionDevice] = useState<IDevice[]>([])
  const [refreshing, setRfereshing] = useState<boolean>(false)
  const { isMobile } = environment || {}

  useEffect(() => {
    updateScreen(defaultScreenName)
  }, [])

  useEffect(() => {
    if (devices) {
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      setSubscriptionDevice([...devices?.filter((e) => e.IsSubscriptionDevice)])
      setOtherDevices([...devices?.filter((e) => !e.IsSubscriptionDevice)])
    }
  }, [devices])

  const launchUrl = () => {
    launchUrlFromCommand(
      '"HPSALauncher.exe" supportwebdashboard %serialNumber% /UseCCC',
      device
    )
  }

  const SingleSkelton = useMemo(
    () => <Skelton single={true} height={20} width={150}></Skelton>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const refreshDevices = async () => {
    setRfereshing(true)
    await refreshDeviceList()
    setRfereshing(false)
  }

  return (
    <>
      <Row>
        <Container>
          <Row className="center m-b-8">
            <SubTitle
              tabIndex={-1}
              id={formatAutoId('title-device-support')}
              className="subtitle-regular"
            >
              {device?.IsSubscriptionDevice
                ? t(
                    'common.hPOnePremiumDeviceSupport2',
                    'HP All-In Plan Premium Device Support'
                  )
                : t('supportHome.deviceSupport', 'Device support')}
            </SubTitle>
            {authProvider?.isSignedIn && (
              <Button
                trackId={genTestId(TestIDPrefix, ProductListRefreshComponent)}
                id={formatAutoId('refresh-devices')}
                appearance="ghost"
                className="ghost"
                loading={refreshing}
                aria-label="refresh"
                onClick={() => refreshDevices()}
                small
                leadingIcon={
                  refreshing ? (
                    <IconReload style={{ visibility: 'hidden' }} size={20} />
                  ) : (
                    <IconReload size={20} />
                  )
                }
              />
            )}
          </Row>
          <Row>
            <Container className="m-b-36">
              <Text
                className="caption regular"
                id={formatAutoId('device-card-desc')}
              >
                <p
                  role="text"
                  tabIndex={-1}
                  id={formatAutoId('dashboard-title-desc')}
                  onClick={(e) => {
                    if (e?.target['tagName']?.toLowerCase() === 'a') {
                      e.preventDefault()
                      launchUrl()
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: authProvider?.isSignedIn
                      ? DOMPurify.sanitize(
                          t('common.toAddOrRemoveDeviceVisitHPSupportWeb', {
                            HPSupportWeb: `<a href="#" class="link inline" id=${formatAutoId(
                              'visit_online_dashboard'
                            )} tabindex="0" >${t('common.HPSupportWeb')}</a>`,
                            interpolation: { escapeValue: false }
                          })
                        )
                      : DOMPurify.sanitize(
                          t('common.dontSeeYourDevice', {
                            HPSupportWeb: `<a href="#" class="link inline" id=${formatAutoId(
                              'visit_online_dashboard'
                            )} tabindex="0" >${t('common.HPSupportWeb')}</a>`,
                            interpolation: { escapeValue: false }
                          })
                        )
                  }}
                ></p>
              </Text>
            </Container>
          </Row>
          {subscriptionDevice?.length > 0 && (
            <>
              {subscriptionDevice?.map((device, index) => (
                <Row key={index} className={index > 0 ? 'm-t-40' : ''}>
                  <DeviceAccordion
                    device={device}
                    key={index}
                  ></DeviceAccordion>
                </Row>
              ))}
              {otherDevices?.length > 0 && (
                <Row>
                  <SubTitle
                    tabIndex={-1}
                    id={formatAutoId('title-device-support')}
                    className="subtitle-regular m-t-40 m-b-16"
                  >
                    {t('common.myOtherDevices2', 'My other devices')}
                  </SubTitle>
                </Row>
              )}
            </>
          )}
          {otherDevices?.length > 0 && (
            <FlexRow
              gap={
                subscriptionDevice?.length == 0 && otherDevices?.length <= 2
                  ? 40
                  : 16
              }
            >
              {otherDevices?.map((device, index) => (
                <Col
                  className="devices"
                  key={index}
                  gridColumn={
                    (subscriptionDevice?.length == 0 &&
                      otherDevices?.length <= 2) ||
                    isMobile
                      ? '1/-1'
                      : 'auto'
                  }
                >
                  <DeviceView
                    key={index}
                    size={
                      isMobile
                        ? TYPE.MOBILE
                        : subscriptionDevice?.length == 0 &&
                          otherDevices?.length <= 2
                        ? TYPE.WINDOWS.SINGLECOLUMN
                        : TYPE.WINDOWS.MULTIPLECOLUMN
                    }
                    isShowWarranty={
                      isMobile == false &&
                      subscriptionDevice?.length == 0 &&
                      otherDevices?.length <= 2
                    }
                    device={device}
                    Skelton={SingleSkelton}
                    getDeviceListWarranty={() =>
                      getDeviceListWarranty(otherDevices)
                    }
                  ></DeviceView>
                </Col>
              ))}
            </FlexRow>
          )}
          {loading && (
            <Row>
              <Skelton className="m-t-40" hide={true}></Skelton>
            </Row>
          )}
        </Container>
      </Row>
    </>
  )
}

export default DeviceSupport
