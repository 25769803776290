import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { ThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import Dashboard from './Dashboard'
import { LocalizationType } from '../types/localization'
const Supportboard = React.lazy(() => import('../mobile/Supportboard/index'))
interface IContext {
  localization?: LocalizationType
  dir?: string
  environment?: any
}

const RootComponent = (props) => {
  const theme = useTheme()
  const { dir, environment } = useContext<IContext>(RootContext)
  return (
    <>
      <ThemeProvider theme={{ ...theme, ...environment, dir }}>
        {environment.isMobile ? (
          <Supportboard {...props}></Supportboard>
        ) : (
          <Dashboard {...props}></Dashboard>
        )}
      </ThemeProvider>
    </>
  )
}

export default RootComponent
