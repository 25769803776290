import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import card from '@veneer/core/dist/esm/scripts/card'
import { HPXTheme, left, right } from '../../styles/global'
import button from '@veneer/core/dist/esm/scripts/button'
import { withAnalytics } from '@veneer/analytics'
const TrackButton = withAnalytics(button)

const flexRow = css`
  display: flex;
  flex-direction: row;
`
const flexColumn = css`
  display: flex;
  flex-direction: column;
`
export const Section = styled.div`
  min-height: auto;
  ${flexColumn};
`

export const Container = styled.div`
  width: 100%;
  min-height: auto;
  ${flexColumn};
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop}px;
    `}
  .icon-color {
    color: ${({ theme }) => theme.colorForeground};
  }
  .image {
    width: 100%;
    object-fit: cover;
  }
  .link {
    cursor: pointer;
  }
  &.m-b-36 {
    margin-bottom: calc(${Tokens.space8} + ${Tokens.space1});
  }
  .m-b-16 {
    margin-bottom: ${Tokens.space4};
  }
  .m-b-8 {
    margin-bottom: ${Tokens.space2};
  }
  .m-t-16 {
    margin-top: ${Tokens.space4};
  }
  .m-t-40 {
    margin-top: calc(${Tokens.space2} + ${Tokens.space8});
  }
  .inline {
    display: inline-flex;
  }
`
export const SubTitle = styled.h6`
  width: 100%;
  color: ${({ theme }) => theme.colorForeground};
`
export const Row = styled.div`
  ${flexRow};
  .m-t-40 {
    margin-top: calc(${Tokens.space8} + ${Tokens.space3});
  }
  &.center {
    display: flex;
    align-items: center;
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`
export const FlexAlign = styled(Row)`
  align-items: center;
`

export const Column = styled.div`
  ${flexColumn};
  flex: 1;
`

export const FlexRow = styled.div`
  display: grid;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : Tokens.space4)};
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      ${(props) => props.minWidth || 300}px,
      ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '1fr')}
    )
  );
  ${HPXTheme.mediaQueries.L} {
    grid-template-columns: repeat(${(props) => props.L || 3}, 1fr);
  }
  ${HPXTheme.mediaQueries.M} {
    grid-template-columns: repeat(${(props) => props.M || 3}, 1fr);
  }
  ${HPXTheme.mediaQueries.S} {
    grid-template-columns: repeat(${(props) => props.S || 2}, 1fr);
  }
`

export const Col = styled.div`
  box-sizing: border-box;
  ${(props) =>
    props.gridColumn &&
    css`
      grid-column: ${props.gridColumn};
    `}
  ${(props) =>
    props.width &&
    css`
      max-width: ${props.width}px;
      flex-basis: ${props.width}px;
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      > div {
        flex: 1;
      }
    `}
`
export const Card = styled(card)``

export const Box = styled(card)`
  ${flexRow};
  &.cardStyle {
    background: ${({ theme }) => theme.colorBackgroundContainer};
    background-color: ${({ theme }) => theme.colorBackgroundContainer};
    border-color: ${({ theme }) => theme.colorBorder};
    border-radius: ${Tokens.borderRadius3};
    cursor: pointer;
  }
  align-items: center;
  padding: ${Tokens.space4};
  &:focus {
    box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  }
  &.p-20 {
    padding: ${Tokens.space5};
  }
  flex: 0 0 calc(100%);
  position: relative;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  cursor: pointer;
  height: 100%;
  &.hp-one-view {
    height: auto !important;
    cursor: default;
    flex-direction: column;
  }
  &.minHeight {
    min-height: 164px;
    > div {
      height: 100%;
      flex: 1;
      margin-${left}: 0px;
    }
  }
  flex: 1;
`

export const ItemTitle = styled.span`
  margin: 0;
  color: ${({ theme }) => theme.colorForeground};
`

export const ItemDescription = styled.span`
  color: ${({ theme }) => theme?.colorForegroundLight};
  flex: 1;
  display: flex;
  align-items: flex-end;
  .nowrap {
    white-space: nowrap;
  }
`

export const ItemContent = styled.div`
  text-align: ${left};
  ${flexColumn};
  flex: 1;
  margin-${left}: ${Tokens.space5};
  .m-b-4 {
    margin-bottom: ${Tokens.space1};
  }
`

export const IconContainer = styled.div`
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${Tokens.space2};
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `} ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
  &.m-b-0 {
    margin-bottom: 0px;
  }
`
export const Text = styled.span`
  color: ${({ theme }) => theme?.colorForeground || Tokens.colorGray10};
`
export const Image = styled.img`
  margin: auto 0;
  margin-${right}: ${Tokens.space6};
  margin-top: 0px;
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  ${(props) => {
    props.width &&
      css`
        width: ${props.width};
      `
  }}

  ${(props) => {
    props.height &&
      css`
        height: ${props.height};
      `
  }}
`

export const Button = styled(TrackButton)`
  &:after {
    display: none;
  }
  &:focus {
    &:after {
      display: unset;
    }
  }
`
