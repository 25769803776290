import React from 'react'
import { ShellProps } from '../src/types/shell'
import { RootComponent } from '../src'
import { ThemeProvider } from '@veneer/theme'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import resources from '../src/assets/locale'
import { DirectionProvider } from '@veneer/core/dist/esm/scripts/direction'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'

import {
  BaseScreenPath,
  Support,
  DefaultScreenName
} from '../src/utils/constants'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root({ ...props }) {
  const { v1 } = window.Shell as ShellProps
  const namespace = '@jarvis/react-hpx-support'
  //const themeProviderProps = v1?.theme?.getThemeProviderProperties?.()
  const theme = props?.themeProvider || {}
  const time = new Date()
  console.log(
    `==================start dashboard MFE: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}==================`
  )
  return (
    <section id={namespace}>
      <JarvisAnalyticsContextProvider
        initialScreenData={{
          screenName: DefaultScreenName,
          screenPath: `${BaseScreenPath}/${Support}/`,
          activity: `${DefaultScreenName}-v01`
        }}
      >
        <DirectionProvider direction={theme?.dir}>
          <ThemeProvider {...theme}>
            <RootProvider
              localization={v1.localization}
              resources={resources}
              useModal={props?.useModal}
              openDialog={props?.openDialog}
              closeDialog={props?.closeDialog}
              formatAutoId={props?.formatAutoId}
              dir={theme.dir}
              navigation={props.navigation}
              optinProvider={props.optinProvider}
              authProvider={props.authProvider}
              featureFlag={props.featureFlag}
              environment={props?.environment}
              navigatePath={props?.navigatePath}
              supportRoute={props?.supportRoute}
            >
              <RootComponent {...props} />
            </RootProvider>
          </ThemeProvider>
        </DirectionProvider>
      </JarvisAnalyticsContextProvider>
    </section>
  )
}
